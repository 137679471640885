/*
@import "../../node_modules/bootstrap/scss/bootstrap";
*/

// Required
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// Optional
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/transitions";
